import React from 'react';
import Comunication from '../image/comunication';
import Shipping from '../image/shipping';
import Offer from '../image/offer';
import './index.scss'

export default function Deals() {
    return (
        <section>
            <h2 className='title-deals libre-bodoni'>Nuestros servicios</h2>
            <div className='components-text'>
                <article>
                    <Comunication />
                    <p>Contacto con nuestro staff</p>
                    <span>Tu proyecto, nuestra prioridad.</span>
                    <span className='margin-bottom'>Contactá con nosotros y aclará todas las dudas y consultas. Trabajamos para individuos, constructoras, estudios y empresas</span>
                </article>
                <article>
                    <Shipping />
                    <p>Envío y colocación gratis</p>
                    <span className='margin-bottom'>Diseñamos, fabricamos e instalamos. Entregamos el mueble en tu domicilio, armado, e instalado sin necesidad de que te preocupes de absolutamente nada.</span>
                </article>
                <article>
                    <Offer />
                    <p>Presupuesto armado a tu medida</p>
                    <span className='margin-bottom'>Personaliza tu hogar con nuestros muebles a medida. Te ofrecemos un servicio completo: diseño, fabricación, entrega e instalación. Tú solo relájate y disfruta del resultado.</span>
                </article>
            </div>
        </section>
    )
}
