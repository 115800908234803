import React from 'react';
import '../components/deals/index.scss'
import Deals from '../components/deals';

export default function About() {
  return (
    <section className='content-center'>
      <h2 className='title-deals libre-bodoni'>Nosotros</h2>
          <p className="text-lg text-gray-700 mb-4">
            En <span className="font-semibold text-gray-900">Amados</span>, nos especializamos en la fabricación e instalación de muebles a medida, 
            diseñados para satisfacer las necesidades y gustos únicos de cada cliente.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            Con más de <span className="font-semibold">10 años de experiencia</span>, hemos perfeccionado nuestro oficio para ofrecer productos 
            de excelente calidad, combinando materiales de primera y acabados impecables.
          </p>
          <p className="text-lg text-gray-700">
            Nuestro compromiso es crear espacios funcionales, elegantes y adaptados a tu estilo de vida. 
            Cada proyecto es único, y trabajamos contigo en cada paso para hacerlo realidad.
          </p>
          <Deals />
  </section>
  )
}
