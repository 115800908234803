import React from 'react'
import organiz1 from '../assets/images/organizadores/1.webp';
import organiz2 from '../assets/images/organizadores/2.webp';
import organiz3 from '../assets/images/organizadores/3.webp';
import organiz4 from '../assets/images/organizadores/4.webp';
import organiz5 from '../assets/images/organizadores/5.webp';
import organiz6 from '../assets/images/organizadores/6.webp'
import ImageComponent from '../components/image-component/imageComponent';

export default function Organizadores() {
  return (
    <section style={{ marginTop: '40px' }}>
      <h2 className='title-deals libre-bodoni'>Organizadores</h2>
      <article className='libre-bodoni'>
      <div className="grid-container">
          <ImageComponent classStyled={'image-max'} urlImage={organiz1} attributAlt={'Cocinas'}/>
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={organiz4} attributAlt={'Cocinas'}/>
            <ImageComponent classStyled={'image-xs'} urlImage={organiz5} attributAlt={'Cocinas'}/>
          </div>
        </div>
        <div className="grid-container">
          <ImageComponent classStyled={'image-max'} urlImage={organiz6} attributAlt={'Cocinas'}/>
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={organiz3} attributAlt={'Cocinas'}/>
            <ImageComponent classStyled={'image-xs'} urlImage={organiz2} attributAlt={'Cocinas'}/>
          </div>
        </div>
      </article>
    </section>
  )
}
