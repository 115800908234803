import React from 'react'
import rack1 from '../assets/images/racks/1.webp';
import rack2 from '../assets/images/racks/2.webp';
import rack4 from '../assets/images/racks/3.webp';
import rack5 from '../assets/images/racks/4.webp';
import rack6 from '../assets/images/racks/5.webp';
import rack7 from '../assets/images/racks/6.webp';
import rack8 from '../assets/images/racks/7.webp';
import rack9 from '../assets/images/racks/8.webp';
import rack3 from '../assets/images/racks/9.webp';
import ImageComponent from '../components/image-component/imageComponent';

export default function Oficinas() {
  return (
    <section style={{ marginTop: '40px' }}>
      <h2 className='title-deals libre-bodoni'>Oficinas y Racks</h2>
      <article className='libre-bodoni'>
        <div className="grid-container">
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={rack2} attributAlt={'Cocinas'}/>
            <ImageComponent classStyled={'image-xs'} urlImage={rack6} attributAlt={'Cocinas'}/>
          </div>
            <ImageComponent classStyled={'image-max'} urlImage={rack1} attributAlt={'Cocinas'}/>
        </div>
        <div className="grid-container">
          <ImageComponent classStyled={'image-max'} urlImage={rack4} attributAlt={'Cocinas'}/>
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={rack5} attributAlt={'Cocinas'}/>
            <ImageComponent classStyled={'image-xs'} urlImage={rack3} attributAlt={'Cocinas'}/>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={rack7} attributAlt={'Cocinas'}/>
            <ImageComponent classStyled={'image-xs'} urlImage={rack8} attributAlt={'Cocinas'}/>
          </div>
            <ImageComponent classStyled={'image-max'} urlImage={rack9} attributAlt={'Cocinas'}/>
        </div>
      </article>
    </section>
  )
}
