import React from 'react'
import cocinas1 from '../assets/images/cocina/1.webp';
import cocinas2 from '../assets/images/cocina/2.webp';
import cocinas3 from '../assets/images/cocina/3.webp';
import cocinas4 from '../assets/images/cocina/4.webp';
import cocinas5 from '../assets/images/cocina/5.webp';
import cocinas6 from '../assets/images/cocina/6.webp'
import ImageComponent from '../components/image-component/imageComponent';

export default function Cocinas() {
  return (
    <section style={{ marginTop: '40px' }}>
      <h2 className='title-deals libre-bodoni'>Cocinas</h2>
      <article className='libre-bodoni'>
        <div className="grid-container">
          <div className="grid-vertical">
            <ImageComponent urlImage={cocinas1} attributAlt={'Cocinas'}/>
            <ImageComponent urlImage={cocinas3} attributAlt={'Cocinas'}/>
          </div>
            <ImageComponent urlImage={cocinas2} attributAlt={'Cocinas'}/>
        </div>
        <div className="grid-container">
          <ImageComponent classStyled={'image-max'} urlImage={cocinas6} attributAlt={'Cocinas'}/>
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={cocinas5} attributAlt={'Cocinas'}/>
            <ImageComponent classStyled={'image-xs'} urlImage={cocinas4} attributAlt={'Cocinas'}/>
          </div>
        </div>
      </article>
    </section>
  )
}
