import React from 'react'
import cocinas1 from '../assets/images/cocina/1.webp';
import cocinas2 from '../assets/images/cocina/2.webp';
import cocinas3 from '../assets/images/cocina/3.webp';

import placar1 from '../assets/images/placares/1.webp';
import placar2 from '../assets/images/placares/2.webp';
import placar3 from '../assets/images/placares/3.webp';

export default function Placares() {
  return (
    <section style={{ marginTop: '80px' }}>
      <h2 className='title-deals libre-bodoni'>Placares</h2>
      <article className='libre-bodoni'>
        <div className="grid-container">
          <div className="grid-vertical">
            <img className="image" src={cocinas1} alt="Cocinas" />
            <img className="image" src={cocinas3} alt="Cocinas" />
          </div>
          <img className="image" src={cocinas2} alt="Cocinas" />
        </div>
        <div className="grid-container">
          <img className="image" src={placar1} alt="Placard" />
          <div className="grid-vertical">
            <img className="image" src={placar2} alt="Placares" />
            <img className="image" src={placar3} alt="Placares" />
          </div>
        </div>
      </article>
    </section>
  )
}
