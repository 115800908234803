// MyIcon.js
import React from 'react';

const FbSvg = () => {
  return (
    <svg width="24" height="24" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.73 71.73">
      <path fill="#3e5c9a" d="M35.86,71.73h0C16.06,71.73,0,55.67,0,35.86h0C0,16.06,16.06,0,35.86,0h0c19.81,0,35.86,16.06,35.86,35.86h0c0,19.81-16.06,35.86-35.86,35.86Z" />
      <path fill="#fff" d="M30.4,57.54h8.73v-21.86h6.09l.65-7.32h-6.74v-4.17c0-1.73.35-2.41,2.02-2.41h4.72v-7.6h-6.05c-6.5,0-9.43,2.86-9.43,8.34v5.84h-4.54v7.41h4.54v21.77Z" />
    </svg>
  );
};

export default FbSvg;
