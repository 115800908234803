import React from 'react'
import whatsapp from '../assets/images/whatsapp.png'

export default function Contact() {
  return (
    <div style={{ maxWidth: "600px", margin: "70px auto", padding: "20px" }}>
      <h2 className='title-deals libre-bodoni'>Contactanos</h2>
      <a className="escribinos-wp" href="https://wa.me/+5492364337038" target="_blank" rel="noopener noreferrer">
        Escribinos por WhatsApp <img src={whatsapp} alt="Icono de WhatsApp" width="50" height="50" />
      </a>
    </div>
  )
}
