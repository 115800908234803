import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/amado.png';
import SvgComponent from '../image/menu';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubMenu, setIsSubMenu] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleSubMenu = (event) => {
        event.preventDefault();
        setIsSubMenu(!isSubMenu);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll',
                handleScroll);
        };
    }, []);

    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <Link to="/"><img src={logo} className="logo" alt="Logo Amado's" /></Link>
            <div className='menu-desktop'>
                <ul className='nav-menu-desktop'>
                    <li className='nav-menu-desktop'><Link to="/">Inicio</Link></li>
                    <li className='nav-menu-desktop'>
                        <button onClick={(event) => toggleSubMenu(event)}>
                            Muebles
                        </button>
                    </li>
                    <li className='nav-menu-desktop'><Link to="/nosotros">Nosotros</Link></li>
                    <li className='nav-menu-desktop'><Link to="/contacto">Contacto</Link></li>
                </ul>
                {isSubMenu && (
                    <ul className='submenu-desktop' >
                        <li className='link-r-d'><Link to="/muebles/cocinas">Cocinas</Link></li>
                        <li className='link-r-d'><Link to="/muebles/placares">Placares</Link></li>
                        <li className='link-r-d'><Link to="/muebles/banos">Baños</Link></li>
                        <li className='link-r-d'><Link to="/muebles/organizadores">Organizadores</Link></li>
                        <li className='link-r-d'><Link to="/muebles/dormitorios">Dormitorios</Link></li>
                    </ul>
                )}
            </div>
            <button className="menu-mobile" onClick={toggleMenu}><SvgComponent /></button>

            {isMenuOpen && (
                    <ul className='submenu-mobile' >
                    <li className='link-r-d'><Link to="/muebles/cocinas">Cocinas</Link></li>
                    <li className='link-r-d'><Link to="/muebles/placares">Placares</Link></li>
                    <li className='link-r-d'><Link to="/muebles/banos">Baños</Link></li>
                    <li className='link-r-d'><Link to="/muebles/organizadores">Organizadores</Link></li>
                    <li className='link-r-d'><Link to="/muebles/dormitorios">Dormitorios</Link></li>
                </ul>
            )}
        </nav>
    );
}

export default Navbar;