import React from 'react';

const MailSvg = () => {
  return (
    <svg width="24" height="24" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.73 71.73">
      <path fill="#df4b38" d="M35.86,71.73h0C16.06,71.73,0,55.67,0,35.86H0C0,16.06,16.06,0,35.86,0h0C55.67,0,71.73,16.06,71.73,35.86h0c0,19.81-16.06,35.86-35.86,35.86Z" />
      <g>
        <path fill="#fff" d="M55.03,25.48v20.77H16.7v-20.77h38.33M55.03,21.89H16.7c-1.98,0-3.58,1.6-3.58,3.58v20.77c0,1.98,1.6,3.58,3.58,3.58h38.33c1.98,0,3.58-1.6,3.58-3.58v-20.77c0-1.98-1.6-3.58-3.58-3.58h0Z" />
        <path fill="#fff" d="M35.86,39.01c-.32,0-.65-.09-.94-.26l-19.16-11.74c-.84-.52-1.11-1.62-.59-2.46.52-.84,1.62-1.11,2.46-.59l18.23,11.16,18.23-11.16c.84-.52,1.95-.25,2.46.59.52.84.25,1.95-.59,2.46l-19.16,11.74c-.29.18-.61.26-.94.26Z" />
      </g>
    </svg>
  );
};

export default MailSvg;