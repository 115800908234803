import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    data-name="Capa 1"
    viewBox="0 0 71.73 71.73"
    {...props}
  >
    <rect
      width={71.73}
      height={9.93}
      y={11.63}
      fill="#fff"
      rx={4.97}
      ry={4.97}
    />
    <rect
      width={71.73}
      height={9.93}
      y={30.9}
      fill="#fff"
      rx={4.97}
      ry={4.97}
    />
    <rect
      width={71.73}
      height={9.93}
      y={50.17}
      fill="#fff"
      rx={4.97}
      ry={4.97}
    />
  </svg>
)
export default SvgComponent
