import React from 'react';

const WpSvg = () => {
  return (
    <svg width="24" height="24" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.73 71.73">
      <path fill="#3dba3c" d="M35.86,71.73h0C16.06,71.73,0,55.67,0,35.86h0C0,16.06,16.06,0,35.86,0h0c19.81,0,35.86,16.06,35.86,35.86h0c0,19.81-16.06,35.86-35.86,35.86Z" />
      <g>
        <path fill="#fff" fillRule="evenodd" d="M55.53,34.87c-.26-10.36-8.8-18.68-19.3-18.68s-18.84,8.13-19.3,18.33c0,.28-.02.56-.02.84,0,3.63,1.01,7.01,2.77,9.9l-3.48,10.28,10.7-3.4c2.77,1.52,5.95,2.39,9.33,2.39,10.67,0,19.31-8.58,19.31-19.17,0-.16,0-.33,0-.49ZM36.22,51.47c-3.3,0-6.37-.98-8.93-2.67l-6.24,1.99,2.03-5.99c-1.94-2.66-3.09-5.92-3.09-9.45,0-.52.03-1.05.08-1.56.8-8.16,7.74-14.55,16.16-14.55s15.53,6.56,16.19,14.86c.04.42.05.83.05,1.26,0,8.89-7.29,16.11-16.24,16.11Z" />
        <path fill="#fff" fillRule="evenodd" d="M45.07,39.23c-.48-.23-2.8-1.37-3.24-1.53-.43-.16-.75-.24-1.07.24-.32.47-1.23,1.53-1.5,1.84-.28.32-.55.35-1.03.12-.47-.24-2-.72-3.81-2.33-1.4-1.24-2.36-2.78-2.63-3.25-.27-.47-.03-.72.21-.96.22-.22.47-.55.71-.82.06-.08.12-.15.17-.23.11-.17.19-.33.3-.56.16-.32.08-.59-.04-.82s-1.07-2.55-1.47-3.49c-.39-.94-.79-.78-1.07-.78s-.59-.04-.9-.04-.83.12-1.27.59c-.43.47-1.65,1.61-1.65,3.92,0,.54.1,1.08.24,1.6.48,1.65,1.51,3.02,1.69,3.26.23.31,3.27,5.21,8.08,7.1,4.82,1.87,4.82,1.25,5.69,1.17.87-.07,2.8-1.13,3.19-2.23.4-1.09.4-2.03.28-2.23-.12-.19-.43-.31-.9-.54Z" />
      </g>
    </svg>
  );
};

export default WpSvg;