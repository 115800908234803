import React, { useState } from 'react';
import './index.scss';
import cocinas1 from '../../assets/images/cocina/1.webp';
import cocinas2 from '../../assets/images/cocina/2.webp';
import cocinas3 from '../../assets/images/cocina/3.webp';

import placar1 from '../../assets/images/placares/1.webp';
import placar2 from '../../assets/images/placares/2.webp';
import placar3 from '../../assets/images/placares/3.webp';


export default function Sections() {
    const [isHoveredCocina, setIsHoveredCocina] = useState(false);
    const [isHoveredPlacard, setIsHoveredPlacard] = useState(false);

    return (
        <section>
            <h2 className='title-deals libre-bodoni'>Nuestros muebles</h2>
            <article className='libre-bodoni'>
                <div
                    onMouseEnter={() => {setIsHoveredCocina(true)}}
                    onMouseLeave={() => {setIsHoveredCocina(false)}}
                    className={`grid-container ${isHoveredCocina ? 'grid-hover' : ''}`}
                >
                    {isHoveredCocina && <div className='grid-text-link'><a href='/muebles/cocinas'> Cocinas</a></div>}
                    <div className="grid-vertical">
                        <img className="image" src={cocinas1} alt="Cocinas" />
                        <img className="image" src={cocinas3} alt="Cocinas" />
                    </div>
                    <img className="image" src={cocinas2} alt="Cocinas" />
                </div>
                <div
                    onMouseEnter={() => {setIsHoveredPlacard(true)}}
                    onMouseLeave={() => {setIsHoveredPlacard(false)}}
                    className={`grid-container ${isHoveredPlacard ? 'grid-hover' : ''}`}
                >
                    {isHoveredPlacard && <div className='grid-text-link'><a href="/muebles/placares">Placares</a></div>}
                    <img className="image" src={placar1} alt="Placard" />
                    <div className="grid-vertical">
                        <img className="image" src={placar2} alt="Placares" />
                        <img className="image" src={placar3} alt="Placares" />
                    </div>
                </div>
            </article>
        </section>
    )
}
