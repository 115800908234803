import React from 'react';
import IgSgv from '../image/footer/ig';
import MailSvg from '../image/footer/mail';
import FbSgv from '../image/footer/fb';
import WpSgv from '../image/footer/wp';
import { Link } from 'react-router-dom';

import './index.scss';


function Footer() {
  return (
    <footer>
      <aside>
        <ul className="footer-link">
          <li className="footer-link"><Link to="/#">Inicio</Link></li>
          <li className="footer-link"><Link to="/nosotros#">Nosotros</Link></li>
          <li className="footer-link"><Link to="/contacto#">Contacto</Link></li>
        </ul>
        <ul className="footer-link">
          <li className="footer-link"><Link to="/muebles/cocinas#">Cocinas</Link></li>
          <li className="footer-link"><Link to="/muebles/placares#">Placares</Link></li>
          <li className="footer-link"><Link to="/muebles/banos#">Baños</Link></li>
        </ul>
        <ul>
        <li className="footer-link"><Link to="/muebles/organizadores#">Organizadores</Link></li>
          <li className="footer-link"><Link to="/muebles/oficinas-racks#">Oficinas y Racks</Link></li>
        </ul>
        <ul>
          <li><MailSvg /><a className="footer-link" href="amadosok@gmail.com" target="_blank">amadosok@gmail.com</a></li>
          <li><IgSgv /><a className="footer-link" href="https://instagram.com/muebles.amados/" target="_blank">@muebles.amados</a></li>
          <li><FbSgv /><a className="footer-link" href="https://m.facebook.com/p/Amados-100063884050764/" target="_blank">@Amados</a></li>
          <li><WpSgv /><a className="footer-link" href="https://wa.me/+5492364337038" target="_blank" rel="noopener noreferrer">+5492364337038</a></li>
        </ul>
      </aside>
      <p>© {new Date().getFullYear()} Amado´s muebles a medida. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;