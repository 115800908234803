import React from 'react'
import cocinas1 from '../assets/images/placares/1.webp';
import cocinas2 from '../assets/images/placares/2.webp';
import cocinas3 from '../assets/images/placares/3.webp';
import placar4 from '../assets/images/placares/4.webp';
import placar5 from '../assets/images/placares/5.webp';
import placar6 from '../assets/images/placares/6.webp';
import ImageComponent from '../components/image-component/imageComponent';

export default function Placares() {
  const placeres = "Placares"
  return (
    <section style={{ marginTop: '40px' }}>
      <h2 className='title-deals libre-bodoni'>Placares</h2>
      <article className='libre-bodoni'>
        <div className="grid-container">
          <ImageComponent classStyled={'image-max'} urlImage={placar5} attributAlt={placeres} />
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={placar6} attributAlt={placeres} />
            <ImageComponent classStyled={'image-xs'} urlImage={placar4} attributAlt={placeres} />
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={cocinas2} attributAlt={placeres} />
            <ImageComponent classStyled={'image-xs'} urlImage={cocinas3} attributAlt={placeres} />
          </div>
            <ImageComponent classStyled={'image-max'} urlImage={cocinas1} attributAlt={placeres} />
        </div>
      </article>
    </section>
  )
}
