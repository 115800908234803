// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Cocinas from './pages/cocinas';
import Placares from './pages/placares';
import Banos from './pages/banos';
import Organizadores from './pages/organizadores';
import Oficinas from './pages/oficinas';
import NavBar from './components/navbar';
import Footer from './components/footer';
import WhatsAppIcon from './components/whatsapp';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/contacto" element={<Contact />} />
        <Route path="/muebles/cocinas" element={<Cocinas />} />
        <Route path="/muebles/placares" element={<Placares />} />
        <Route path="/muebles/banos" element={<Banos />} />
        <Route path="/muebles/organizadores" element={<Organizadores />} />
        <Route path="/muebles/oficinas-racks" element={<Oficinas />} />
      </Routes>
      <WhatsAppIcon />
      <Footer />
    </Router>
  );
}

export default App;
