import React from 'react'
import banos1 from '../assets/images/banos/_1.webp';
import banos2 from '../assets/images/banos/_2.webp';
import banos3 from '../assets/images/banos/_3.webp';
import banos4 from '../assets/images/banos/1.webp';
import banos5 from '../assets/images/banos/2.webp';
import banos6 from '../assets/images/banos/3.webp';
import ImageComponent from '../components/image-component/imageComponent';

export default function Banos() {
  const banios = "baños";

  return (
    <section style={{ marginTop: '40px' }}>
      <h2 className='title-deals libre-bodoni'>Baños</h2>
      <article className='libre-bodoni'>
        <div className="grid-container">
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={banos4} attributAlt={banios} />
            <ImageComponent classStyled={'image-xs'} urlImage={banos5} attributAlt={banios} />
          </div>
          <ImageComponent classStyled={'image-max'} urlImage={banos6} attributAlt={banios} />
        </div>
        <div className="grid-container">
          <ImageComponent classStyled={'image-max'} urlImage={banos1} attributAlt={'Cocinas'} />
          <div className="grid-vertical">
            <ImageComponent classStyled={'image-mini'} urlImage={banos2} attributAlt={'Cocinas'} />
            <ImageComponent classStyled={'image-xs'} urlImage={banos3} attributAlt={'Cocinas'} />
          </div>
        </div>
      </article>
    </section>
  )
}
