// MyIcon.js
import React from 'react';

const IgSvg = () => {
  return (
    <svg width="24" height="24" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.73 71.73">
      <path fill="#B44087" d="M35.86,71.73h0C16.06,71.73,0,55.67,0,35.86h0C0,16.06,16.06,0,35.86,0h0c19.81,0,35.86,16.06,35.86,35.86h0c0,19.81-16.06,35.86-35.86,35.86Z" />
      <g>
        <path fill="#fff" d="M35.86,19.74c5.25,0,5.87.02,7.95.11,1.92.09,2.96.41,3.65.68.92.36,1.57.78,2.26,1.47.69.69,1.11,1.34,1.47,2.26.27.69.59,1.73.68,3.65.09,2.07.11,2.7.11,7.95s-.02,5.87-.11,7.95c-.09,1.92-.41,2.96-.68,3.65-.36.92-.78,1.57-1.47,2.26-.69.69-1.34,1.11-2.26,1.47-.69.27-1.73.59-3.65.68-2.07.09-2.69.11-7.95.11s-5.87-.02-7.95-.11c-1.92-.09-2.96-.41-3.65-.68-.92-.36-1.57-.78-2.26-1.47-.69-.69-1.11-1.34-1.47-2.26-.27-.69-.59-1.73-.68-3.65-.09-2.07-.11-2.7-.11-7.95s.02-5.87.11-7.95c.09-1.92.41-2.96.68-3.65.36-.92.78-1.57,1.47-2.26.69-.69,1.34-1.11,2.26-1.47.69-.27,1.73-.59,3.65-.68,2.07-.09,2.7-.11,7.95-.11M35.86,16.2c-5.34,0-6.01.02-8.11.12-2.09.1-3.52.43-4.77.91-1.29.5-2.39,1.17-3.48,2.27-1.09,1.09-1.77,2.19-2.27,3.48-.49,1.25-.82,2.68-.91,4.77-.1,2.1-.12,2.77-.12,8.11s.02,6.01.12,8.11c.1,2.09.43,3.52.91,4.77.5,1.29,1.17,2.39,2.27,3.48s2.19,1.77,3.48,2.27c1.25.49,2.68.82,4.77.91,2.1.1,2.77.12,8.11.12s6.01-.02,8.11-.12c2.09-.1,3.52-.43,4.77-.91,1.29-.5,2.39-1.17,3.48-2.27,1.09-1.09,1.77-2.19,2.27-3.48.49-1.25.82-2.68.91-4.77.1-2.1.12-2.77.12-8.11s-.02-6.01-.12-8.11c-.1-2.09-.43-3.52-.91-4.77-.5-1.29-1.17-2.39-2.27-3.48-1.09-1.09-2.19-1.77-3.48-2.27-1.25-.49-2.68-.82-4.77-.91-2.1-.1-2.77-.12-8.11-.12" />
        <path fill="#fff" d="M35.86,25.77c-5.58,0-10.1,4.52-10.1,10.1s4.52,10.1,10.1,10.1,10.1-4.52,10.1-10.1-4.52-10.1-10.1-10.1M35.86,42.42c-3.62,0-6.55-2.93-6.55-6.55s2.93-6.55,6.55-6.55,6.55,2.93,6.55,6.55-2.93,6.55-6.55,6.55" />
        <path fill="#fff" d="M48.72,25.37c0,1.3-1.06,2.36-2.36,2.36s-2.36-1.06-2.36-2.36,1.06-2.36,2.36-2.36,2.36,1.06,2.36,2.36" />
      </g>
    </svg>
  );
};

export default IgSvg;
